import React from 'react'

const Step6 = (): React.ReactElement => (
  <div>
    <div className="text">
      <h3>Dig into Liquidity Pool Transactions</h3>
      <p>
        Expand Liquidity Sidebar to explore mints (adds) and burns (removes) to market pools.
        Besides, you’ll be able to see liquidity charts and inspect top liquidity pairs.
      </p>
    </div>
  </div>
)

export default Step6
