import React from 'react'

const Step5 = (): React.ReactElement => (
  <div>
    <div className="text">
      <h3>Dig into Market Transactions</h3>
      <p>
        You can expand Trading Volume Sidebar. This allows you to see in-depth information about
        on-chain transactions.
      </p>
    </div>
  </div>
)

export default Step5
