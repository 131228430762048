import classNames from 'classnames'
import React, { Component } from 'react'

interface OutsideClickerProps {
  clickHide: () => void
  className?: string
}

export default class OutsideClicker extends Component<OutsideClickerProps> {
  wrapperRef: null | HTMLDivElement = null

  constructor(props: OutsideClickerProps) {
    super(props)

    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount(): void {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setWrapperRef(node: HTMLDivElement): void {
    this.wrapperRef = node
  }

  handleClickOutside(event: Event): void {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target as Node)) {
      this.props.clickHide()
    }
  }

  render(): React.ReactElement {
    return (
      <div
        ref={this.setWrapperRef}
        className={classNames('click-wrapper', this.props.className || '')}>
        {this.props.children}
      </div>
    )
  }
}
