import React from 'react'

const Step3 = (): React.ReactElement => (
  <div>
    <div className="text">
      <h3>Add Token to Favorites</h3>
      <p>
        Found something interesting? You can add a token to your Favorites, pressing the heart
        button.
      </p>
      <p>
        You can also turn on push notifications in ⚙️ Settings if you want to stay up-to-date with
        price movements for tokens in your Favorites list.
      </p>
    </div>
  </div>
)

export default Step3
