import React from 'react'

const Step1 = (): React.ReactElement => (
  <div>
    <h3>Connect Wallet</h3>
    <div className="text">
      <p>
        Some of our features are only available once you connect a wallet. After connecting it, you
        will be able to save your favorite tokens and change DexGuru settings.
      </p>
      <p>
        DexGuru is a fully non-custodial platform, so the assets in your wallet are always under
        your control.
      </p>
    </div>
  </div>
)

export default Step1
