import React from 'react'

const Step7 = (): React.ReactElement => (
  <div>
    <h3>Repeat the Onboarding</h3>
    <div className="text">
      <p>Repeat these instructions anytime by pressing the question mark in the footer.</p>
    </div>
  </div>
)

export default Step7
