import React from 'react'

const Step2 = (): React.ReactElement => (
  <div>
    <div className="text">
      <h3>Market Selector</h3>
      <p>To see the token charts pick it inside the Market Selector.</p>
      <p>
        You can look up any token that has on-chain liquidity on one of the decentralized exchanges
        by ticker or smart contract address.
      </p>
    </div>
  </div>
)

export default Step2
