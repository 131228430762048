import { Options } from 'intro.js'
import { Step } from 'intro.js-react'

import StepDocs from './Steps/Docs'
import StepFavoritesAdd from './Steps/FavoritesAdd'
import StepFavoritesOpen from './Steps/FavoritesOpen'
import StepLiquidity from './Steps/Liquidity'
import StepOmnibox from './Steps/Omnibox'
import StepOnboarding from './Steps/Onboarding'
import StepTransactions from './Steps/Transactions'
import StepWallet from './Steps/Wallet'
import WelcomePage from './WelcomePage'

export const introSteps: { steps: Step[]; enabled: boolean; initialStep: number } = {
  enabled: true,
  initialStep: 0,
  steps: [
    {
      intro: <WelcomePage />,
      position: 'auto',
      tooltipClass: 'welcome-page',
    },
    {
      element: '.wallet-widget__icon',
      intro: <StepWallet />,
    },
    {
      element: '.omnibox__intro-ref',
      intro: <StepOmnibox />,
    },
    {
      element: '.omnibox-token__favorite',
      intro: <StepFavoritesAdd />,
    },
    {
      element: '.header__button--favorite',
      intro: <StepFavoritesOpen />,
    },
    {
      element: '#history-sidebar-open',
      intro: <StepTransactions />,
    },
    {
      element: '#liquidity-sidebar-open',
      intro: <StepLiquidity />,
    },
    {
      element: '#onboarding-docs',
      position: 'top',
      intro: <StepDocs />,
    },
    {
      element: '#reopen-onboarding',
      intro: <StepOnboarding />,
      tooltipClass: 'last-page',
    },
  ],
}

export const OPTIONS: Options = {
  skipLabel: '',
  doneLabel: 'Close',
  showStepNumbers: false,
  showBullets: true,
  showProgress: false,
  exitOnOverlayClick: false,
}
