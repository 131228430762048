import React from 'react'

const Step6 = (): React.ReactElement => (
  <div>
    <div className="text">
      <h3>Docs &amp; Community</h3>
      <p>Check out our documentation on GitBook for more information.</p>
      <p>Join our Community Discord to share your feedback and chat with fellow traders.</p>
    </div>
  </div>
)

export default Step6
