import { Steps } from 'intro.js-react'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { initIsOnboardingVisible, setIsOnboardingVisible } from '../../actions'
import { useComponentDidMount } from '../../hooks/useComponentDidMount'
import { State } from '../../reducers'
import { introSteps, OPTIONS } from './resources'

const OnboardingDesktop: FC = () => {
  const reduxDispatch = useDispatch()
  const isOnboardingVisible = useSelector((state: State) => state.isOnboardingVisible)

  useComponentDidMount(() => {
    reduxDispatch(initIsOnboardingVisible())
  })

  const onOnboardingStart = (): void => {
    defineSkip()
  }

  const onOnboardingExit = (_stepNumber: number): void => {
    reduxDispatch(setIsOnboardingVisible(false))
  }

  const onOnboardingSkip = (): void => {
    reduxDispatch(setIsOnboardingVisible(false))
  }

  const defineSkip = (): void => {
    // intro.js-react does not include skip logic while intro.js does
    // still the benefits of react wrapper are worth this crappy hack
    const elements = document.getElementsByClassName(
      'introjs-button introjs-prevbutton introjs-disabled'
    )
    elements[0]?.addEventListener('click', () => {
      const elements = document.getElementsByClassName('welcome-page')
      if (elements.length) {
        onOnboardingSkip()
      }
    })
  }

  return isOnboardingVisible ? (
    <Steps
      onStart={onOnboardingStart}
      options={OPTIONS}
      enabled={introSteps.enabled}
      steps={introSteps.steps}
      initialStep={introSteps.initialStep}
      onExit={onOnboardingExit}
    />
  ) : null
}

export default OnboardingDesktop
