import React from 'react'

const Step4 = (): React.ReactElement => (
  <div>
    <div className="text">
      <h3>How to access Favorites</h3>
      <p>
        Smiling Emoji 😍 is a quick way to access Favorites. Your favorite markets are just one
        click away!
      </p>
    </div>
  </div>
)

export default Step4
