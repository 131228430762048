import React from 'react'

import IntroImage from './whattheheck.png'

const WelcomePage = (): React.ReactElement => (
  <div>
    <div className="text">
      <h3>Welcome to DexGuru</h3>
      <p>
        <img src={IntroImage} width={460} height={310} alt="What is DexGuru?" />
      </p>
      <p>
        DexGuru combines advanced analytics and research tools with powerful trade execution
        capabilities to help you analyze permissionless, real-time data for every on-chain market —
        all in one fully-integrated UI.
      </p>
      <p>
        We created this quick optional onboarding process for new users that will highlight our main
        features.
      </p>
      <p>Would you like to start the tour?</p>
    </div>
  </div>
)

export default WelcomePage
